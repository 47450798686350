import React, {useEffect, useState} from 'react';
import {Container, Col, Row} from 'reactstrap';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import {faQuestionCircle} from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import useBalanceText from 'utils/useBalanceText';

import {SectionTriangle} from 'components/Elements/Section';
import {ListIcon} from 'components/Elements/IconList';
import SalesNav from 'components/SalesNav';
import TestimonialCarousel from 'components/TestimonialCarousel';
import {TestimonialBubble} from 'components/Testimonial';
import ModuleAccordion from 'components/ModuleAccordion';
import {BonusAccordion} from 'components/BonusList';
import {InstructorCardList} from 'components/InstructorCardList';
import {GuaranteeBox} from 'components/Guarantee';
import {FaqCard} from 'components/FaqList';

// @ts-ignore
import {enrollItems} from 'data/ppt.json';

export default function SalesCopy({version, variant, salesFullPrice = true, children, ...props}) {
  function SalesCtaSection({id, className = null, type = 'bottom', ...props}) {
    return (
      <SectionTriangle id={id} color="purple" className={className} type={type}>
        <Container>
          <Row>
            <Col>{children}</Col>
          </Row>
        </Container>
      </SectionTriangle>
    );
  }

  useBalanceText();
  return (
    <>
      <SalesNav />

      <div id="why">
        <SectionTriangle id="" color="white" type="bottom">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center mb-5">
                  In Plant-Powered & Thriving, we’ll teach you how to:
                </h2>
                <ListIcon iconColor="purple" number>
                  <>
                    <h6 className="section-heading text-purple mb-2">Experience more vitality and positivity</h6>
                    <p>
                      Take pleasure in wholesome food that can help reduce brain fog and help you perform at your best
                      all day long. Feel confident achieving your health goals knowing you’re eating proven anticancer,
                      anti-heart disease, and anti-Alzheimer’s foods.
                    </p>
                  </>
                  <>
                    <h6 className="section-heading text-purple mb-2">
                      Reduce your grocery bill so you can save money while eating well
                    </h6>
                    <p>
                      Learn the 5 keys to saving money while eating delicious and healthy food. Some families can save
                      $2,000/year or more with these simple tips!
                    </p>
                  </>
                  <>
                    <h6 className="section-heading text-purple mb-2">Save time with quick & easy prep</h6>
                    <p>Use PROVEN SHORTCUTS to prepare simple healthy meals & snacks when you’re in a hurry.</p>
                  </>
                  <>
                    <h6 className="section-heading text-purple mb-2">
                      Plan effectively so you can make good food choices with confidence
                    </h6>
                    <p>Find out how to save time, boost energy, and reduce stress. We’ll show you how!</p>
                  </>
                  <>
                    <h6 className="section-heading text-purple mb-2">Dine out smartly</h6>
                    <p>Be able to CONFIDENTLY select healthier fare when eating out.</p>
                  </>
                  <>
                    <h6 className="section-heading text-purple mb-2">
                      Feel great about what you’re putting in your body with guilt-free comfort foods
                    </h6>
                    <p>
                      Find out how to create healthier versions of some of your favorite comfort foods. Enjoy the most
                      delicious healthy recipes of your life while getting all the nutrients you need.
                    </p>
                  </>
                  <>
                    <h6 className="section-heading text-purple mb-2">
                      Eat and live in a way that protects and heals the Earth
                    </h6>
                    <p>
                      Enjoy living in a way that’s humane and contributes to a healthier planet for future generations.
                    </p>
                  </>
                  <>
                    <h6 className="section-heading text-purple mb-2">
                      Bring more joy and gratitude to your meals and your community
                    </h6>
                    <p>
                      Not just for yourself but for your family and friends as well, regardless of their dietary
                      preferences. Experience unwavering support from a thriving community that fosters your journey
                      toward success.
                    </p>
                  </>
                </ListIcon>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="light-gray" type="bottom">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center">What do participants have to say?</h3>
                <p className="balance-text text-center text-small mb-5">
                  <i>
                    These stories are from people who’ve found success with our programs. Each of them is unique. And so
                    are you! Your experiences will vary, of course. And no, we never offer compensation for these
                    statements!
                  </i>
                </p>
                <TestimonialCarousel
                  list={[
                    'shelley',
                    variant === 'ad' ? 'margaret-p-ad' : 'margaret-p',
                    'sheila-m',
                    'chuck-g',
                    'susan',
                    'terri-c'
                  ]}
                />
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SalesCtaSection id="" />

        <SectionTriangle id="ppt-guarantee" color="light-gray" type="bottom">
          <Container>
            <Row>
              <Col>
                <GuaranteeBox
                  title={
                    <h3 className="section-heading guarantee-heading text-center text-sm-left">
                      You’re Protected By Our 60-Day, Unconditional Money-Back Guarantee
                    </h3>
                  }
                >
                  <p>
                    <b>Try it out.</b> If you aren't completely blown away by the course, even if you just don’t like
                    it, just email us at{' '}
                    <a className="link-black text-underline" href="mailto:support@foodrevolution.org">
                      support@foodrevolution.org
                    </a>
                    , any time within 60 days after purchase to ask for a prompt and courteous refund. This means you
                    can even take the whole course, take every lesson, and make every single mouth-watering recipe, and
                    if it doesn’t rock your world, you get every penny back. That’s how confident we are that you’ll
                    LOVE Plant-Powered & Thriving!
                  </p>
                  <p>
                    PLUS, if for any reason you request a refund, the entire Plant-Powered & Thriving course —
                    everything that you’ve downloaded — is still yours to keep. That’s why we call our guarantee “better
                    than money back.”
                  </p>
                </GuaranteeBox>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>
      </div>

      <div id="modules">
        <SectionTriangle id="" color="black" type="bottom">
          <Container>
            <Row>
              <Col>
                <h2 className="section-only-heading text-center text-white">
                  Become a Seasoned Plant-Powered Pro With These Seven Modules:
                </h2>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="white" type="bottom">
          <Container>
            <Row>
              <Col>
                <ModuleAccordion />
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="black" type="bottom">
          <Container>
            <Row>
              <Col>
                <h2 className="section-only-heading text-center text-white">
                  Here’s Everything You Get When You Enroll:
                </h2>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="white" type="bottom">
          <Container>
            <Row>
              <Col>
                {enrollItems.map((item, i) => {
                  if (
                    version === 'sales' &&
                    item.text ===
                      '**BONUS (on this page only): Ultimate Health Collection**  \nGet 20 of the most popular interviews between John and the world’s leading food experts as they share the most critical food and health developments you need to protect yourself.'
                  ) {
                    return null;
                  }
                  return (
                    <div key={'enrollItem' + i} className={classNames(`enroll-item`, `p-4`, i != 0 && 'border-top')}>
                      <Row className="align-items-center justify-content-center">
                        <Col xs="8" md="3" lg="2" className="mb-4 mb-md-0">
                          <img src={item.image.url} alt={item.image.alt} />
                        </Col>
                        <Col md="9" lg="10" className="enroll-item-text text-center text-md-left">
                          <ReactMarkdown source={item.text} />
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="light-gray" type="bottom" className="text-center">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Curious about the Exclusive Forum?</h3>
                <p className="balance-text">
                  Get access to our private and moderated online community forum, where you can connect with peers,
                  share struggles and successes, and receive expert advice.
                </p>
                <p className="balance-text">
                  When you register for Plant-Powered & Thriving, you’ll become part of an inspired, vibrant community
                  of individuals just like you who are excited to be part of the food revolution. This is more than a
                  course — it’s a community!
                </p>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>
      </div>

      <div id="bonuses">
        <SectionTriangle id="" color="black" type="bottom">
          <Container>
            <Row>
              <Col>
                <h2 className="section-only-heading text-center text-white">
                  And There’s Even More! Enroll Now For These Powerful Bonuses
                </h2>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="white" type="bottom">
          <Container>
            <Row>
              <Col>
                <p className="balance-text text-center">
                  When you join Plant-Powered & Thriving, you’ll gain access to a powerful collection of free bonuses
                  from our friends and partners!
                </p>
              </Col>
            </Row>
            <Row className="m-0">
              <BonusAccordion />
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="light-gray" type="bottom">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center">
                  Success Stories From the Plant-Powered & Thriving Community
                </h3>
                <p className="balance-text text-center text-small mb-5">
                  <i>
                    These stories are from people who’ve found success with our programs. Each of them is unique. And so
                    are you! Your experiences will vary, of course. And no, we never offer compensation for these
                    statements!
                  </i>
                </p>
                <TestimonialCarousel
                  list={[
                    'patsy',
                    'betsy-g',
                    'liz',
                    'shayne-t',
                    variant === 'ad' ? 'ingrid-s-ad' : 'ingrid-s',
                    'kari-z'
                  ]}
                />
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SalesCtaSection id="" />

        {version === 'screening' && (
          <>
            <SectionTriangle id="" color="white" type="bottom" className="text-center">
              <Container>
                <Row>
                  <Col>
                    <h3 className="section-heading">About Today's Special Enrollment Discount</h3>
                    <p className="balance-text">
                      This special discount is only for Masterclass attendees — as a reward for investing an hour in
                      your growth by watching the Masterclass. You can unlock a special price only on this page. With
                      this Special Masterclass Price, you save $250 by enrolling in Plant-Powered & Thriving today.
                    </p>
                    <p className="balance-text">
                      <b className="text-purple">IMPORTANT!</b> Only order from this page to get our best offer. The
                      enrollment price is higher everywhere else on our site.
                    </p>
                  </Col>
                </Row>
              </Container>
            </SectionTriangle>

            <SectionTriangle id="" color="black" type="bottom">
              <Container>
                <Row>
                  <Col>
                    <h2 className="section-only-heading text-center text-white">
                      Why Order Today: 3 Powerful Advantages Only Available Now
                    </h2>
                  </Col>
                </Row>
              </Container>
            </SectionTriangle>

            <SectionTriangle id="" color="white" type="bottom">
              <Container>
                <Row>
                  <Col>
                    <ListIcon iconColor="purple" number>
                      <>
                        <h5 className="section-heading text-purple mb-3">
                          The Lowest Price That Will Ever Be Offered.
                        </h5>
                        <p>
                          The regular price for Plant-Powered & Thriving is $497. And for all that it includes, that’s
                          already a huge bang for your buck! But when you enroll today, you'll invest only $247 — this
                          is a MASSIVE $250 discount. (Prefer a three-month payment plan? We’ve got you covered! Join
                          now for just $89/month!)
                        </p>
                        <p>
                          You just chose to invest your time in our Masterclass, and this means you’re probably already
                          one of the special people who know that food matters, and who’s willing to eat differently,
                          and to think differently, than the vast majority of the people around you. In other words,
                          you’re exactly the kind of person we want in this program so that we can accomplish our goal
                          of mobilizing millions for healthy people and a healthy planet!
                        </p>
                        <p>So we want to make it easy for you to say yes, and to say yes, now.</p>
                      </>
                      <>
                        <h5 className="section-heading text-purple mb-3">
                          Get the Ultimate Health Collection as a totally free bonus.
                        </h5>
                        <p>
                          Since 2012, John Robbins has personally interviewed hundreds of the world’s leading food
                          experts in our annual Food Revolution Summits. We compiled 20 of the most popular interviews
                          he’s ever conducted on the topics of food and health and put them into this Ultimate Health
                          Collection. Experts include Michael Pollan, Rip Esselstyn, Dr. Dale Bredesen, Dan Buettner,
                          Dr. T. Colin Campbell, Kris Carr, Dr. Kim Williams, Tony Robbins, and more! The only other way
                          to directly buy this curated collection would be to purchase the Empowerment Package from all
                          of our past Food Revolution Summits and to spend more than $2,000 in the process. But it’s a
                          totally free bonus only when you sign up right here. And it comes with streaming access, plus
                          downloadable MP3s and transcripts from all 20 brilliant presentations.
                        </p>
                      </>
                      <>
                        <h5 className="section-heading text-purple mb-3">
                          Join With Other Participants Who Are Ready Now, Too
                        </h5>
                        <p>
                          The best way to ensure that your healthy intentions become reality is to join a community on
                          the same mission as you!
                        </p>
                        <ListIcon iconColor="purple">
                          <>
                            Receive answers from our health coaches and nutritionists anytime you have a question — this
                            is worth the cost of the program alone!
                          </>
                          <>Get the latest health updates and groundbreaking scientific studies.</>
                          <>
                            Share recipes, struggles, and successes, and stay inspired by those who share your goal to
                            be healthy.
                          </>
                        </ListIcon>
                        <p>
                          This is the warmest and most engaged moderated online community you can find. Your success
                          depends on the company you keep!
                        </p>
                      </>
                    </ListIcon>
                  </Col>
                </Row>
              </Container>
            </SectionTriangle>

            <SalesCtaSection id="" />
          </>
        )}
      </div>

      <div id="instructors">
        <SectionTriangle id="" color="white" type="bottom">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center mb-4">Meet Your Instructors</h2>
                <Row className="align-items-stretch justify-content-center">
                  <InstructorCardList />
                </Row>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle
          id=""
          color="black"
          imgUrl="https://cdn.foodrevolution.org/ppt/images/175234125.jpg"
          angle="none"
          offset="bottom"
        >
          <Container>
            <Row>
              <Col>
                <div className="background-white rounded box-shadow-black-50 p-3 p-lg-5 text-center text-lg-left">
                  <Row className="d-flex align-items-center justify-content-center mb-lg-4">
                    <Col xs="12" sm="8" lg="4" xl="3" className="order-lg-2 text-center mb-4 mb-lg-0">
                      <img
                        className="img-ocean-note rounded-circle"
                        // @ts-ignore
                        src={require('static/speaker/ocean-robbins.jpg')}
                      />
                    </Col>
                    <Col xs="12" lg="8" xl="9" className="order-lg-1">
                      <h2 className="section-heading mb-4">A personal note from Ocean Robbins</h2>
                      <h4 className="my-4">
                        In his 70s, my Grandpa Irv (who founded Baskin-Robbins ice cream company) became seriously
                        ill...
                      </h4>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center justify-content-center">
                    <Col xs="12" sm="8" lg="4" xl="3" className="img-ocean-note-icecream-row text-center">
                      <img
                        className="img-ocean-note img-ocean-note-icecream img-fluid img-rotate-left"
                        // @ts-ignore
                        src={require('static/irvine-with-ice-cream.jpg')}
                      />
                    </Col>
                    <Col xs="12" lg="8" xl="9">
                      <p>
                        <strong>
                          He’d always eaten the standard American diet, plus a double scoop of ice cream on top, and now
                          he was suffering from the standard American diseases.
                        </strong>
                      </p>
                      <h5 className="my-4">That was his wake-up call.</h5>
                      <p>
                        His doctor told him that he'd have to take seven medications three times every day for the rest
                        of his life and that unless he changed his diet, he likely had only a few years to live — in
                        poor health.
                      </p>
                      <p>And then his doctor gave Grandpa Irv a book and told him to read it.</p>
                    </Col>
                  </Row>
                  <p>
                    The book was already familiar to him because it was written by his son (my dad), John Robbins. But
                    he hadn’t read it.
                  </p>
                  <p>
                    You see, there were some hard feelings in the family. Decades earlier, my grandpa had been grooming
                    my dad, John, to one day join in the family business.
                  </p>
                  <p>
                    But when he was in his early 20’s, my dad wound up walking away from Baskin-Robbins and the wealth
                    it represented because it didn't align with his values of health and compassion.
                  </p>
                  <p>
                    My dad left that legacy to follow his own "rocky road" and become a best-selling food and health
                    author. The media had fun with the story, calling him the “rebel without a cone.”
                  </p>
                  <p>
                    Millions of people were inspired by his work — including, as fate would have it, Grandpa Irv’s
                    doctor, who was now recommending my dad's best-selling book, Diet for a New America, to his patient.
                  </p>
                  <Row className="d-flex justify-content-center">
                    <Col xs="12" lg="7" xl="8">
                      <p>And amazingly enough, Grandpa Irv read the book and made some big changes.</p>
                      <p>
                        He added lots of vegetables and fruits; he went plant-powered, and he even gave up ice cream!
                      </p>
                      <h4 className="my-4">
                        My Grandpa Irv ended up getting off all his medications and adding almost 20 more healthy years
                        to his life.
                      </h4>
                    </Col>
                    <Col xs="12" sm="8" lg="5" xl="4" className="img-ocean-note-suits-row text-center">
                      <img
                        className="img-ocean-note img-ocean-note-suits img-fluid img-rotate-right"
                        // @ts-ignore
                        src={require('static/irvine-john-ocean.jpg')}
                      />
                    </Col>
                  </Row>

                  <Row className=" d-flex justify-content-center align-items-center">
                    <Col xs="12" lg="8" className="order-lg-2">
                      <p>
                        The great news is that this is the kind of transformation I know is possible for anyone,
                        including you and your loved ones.
                      </p>
                      <h4 className="my-4">That's how POWERFUL food is.</h4>
                      <p>
                        My dad and I have spent decades living our passion to empower millions of people with this
                        life-saving knowledge.
                      </p>
                    </Col>
                    <Col xs="12" sm="8" lg="4" className="img-ocean-note-flexing-row text-center order-lg-1">
                      <img
                        className="img-ocean-note img-ocean-note-flexing img-fluid rounded-circle"
                        // @ts-ignore
                        src={require('static/speaker/john-ocean-square.jpg')}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="white" type="both">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center">Are you ready to take control of your health?</h3>
                <h5 className="text-center mt-0">This course will empower you to…</h5>
                <ListIcon iconColor="purple">
                  <>Get the scientifically proven essential nutrients your body needs</>
                  <>Make living a healthy life easy</>
                  <>Eat deliciously satisfying and deeply nourishing food without breaking the bank</>
                  <>Prepare meals without having to toil over the stove for hours on end</>
                  <>Get your loved ones to eat healthfully (without conflict!)</>
                  <>Utilize simple hacks to make wholesome food taste amazing</>
                  <>
                    Enjoy a plant-powered diet that’s been proven to not just help you be fit – but also to prevent
                    cancer, heart disease, diabetes, and many other ailments – deliciously!
                  </>
                  <>
                    Get your hardest questions answered. John, Ocean, Chef Cynthia, and our team of seasoned food
                    revolution leaders are here for YOU. We’ll delight in answering your questions about healthy food!
                  </>
                </ListIcon>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="light-gray" type="bottom">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center">More Testimonials from Course Participants</h3>
                <p className="balance-text text-center text-small mb-5">
                  <i>
                    These stories are from people who’ve found success with our programs. Each of them is unique. And so
                    are you! Your experiences will vary, of course. And no, we never offer compensation for these
                    statements!
                  </i>
                </p>
                <TestimonialCarousel
                  list={[
                    'linda',
                    'denise-a',
                    'marium-a',
                    variant === 'ad' ? 'eric-c-ad' : 'eric-c',
                    'rhonda-h',
                    'france-t'
                  ]}
                />
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SalesCtaSection id="" />

        <SectionTriangle id="" color="white" type="bottom" className="text-center">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">
                  Junk food – even “healthy” junk food – is addictive. And addictions cost… big time.
                </h3>
                <p>
                  Food addictions lead to spending money on unhealthy foods, of course. But they also lead to higher
                  medical costs, lower energy, reduced productivity at work, and a less vibrant and joyful life. There
                  is a direct link between the food you eat and your level of physical, emotional, and mental wellness.
                </p>
                <p>When your body actually gets the nourishment it needs, it’s satisfied.</p>
                <h4 className="my-4">The truth is, your health is priceless.</h4>
                <p>
                  John, Ocean, and Cynthia have spent decades researching healthy eating and helping millions of people
                  put it into practice. This course distills their insights and helps put you on the path of vibrant
                  health.
                </p>
                <p>
                  <b>
                    <i>What’s that worth?</i>
                  </b>
                </p>
                <p>
                  What’s it worth to have your health and to have habits in place that make it easy for you to save time
                  and money doing the right thing for you and your loved ones?
                </p>
                <p>
                  The reality is that the information in this course could be sold for thousands of dollars. But, this
                  is too important to keep it expensive and exclusive.
                </p>
                {
                  {
                    screening: (
                      <p>
                        We’re on a mission to change the way the world eats and to help millions of people to implement
                        and thrive with a plant-powered lifestyle. So we priced it as low as we could while still
                        (hopefully!) covering our costs, and are offering the course for the special price of only $497.
                        And right now, because you joined this Masterclass and we want as many people as possible to be
                        able to benefit from this course, we’re offering an{' '}
                        <b>
                          <i>even lower price</i>
                        </b>{' '}
                        for a very limited time. So you get to SAVE $250.
                      </p>
                    ),
                    sales: (
                      <p>
                        We’re on a mission to change the way the world eats and to help millions of people to implement
                        and thrive with a plant-powered lifestyle. So we priced it as low as we could while still
                        (hopefully!) covering our costs, and are offering the course for the special price of only $497.{' '}
                        {!salesFullPrice && (
                          <>
                            And right now, because we want as many people as possible to be able to benefit from this
                            course, we’re offering an{' '}
                            <b>
                              <i>even lower price</i>
                            </b>{' '}
                            for a very limited time. So you get to SAVE $200.
                          </>
                        )}
                      </p>
                    )
                  }[version]
                }
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="light-gray" type="bottom">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center">
                  To really THRIVE, you need a balanced mix of the right whole foods.
                </h3>
                <ListIcon iconColor="purple" icon={faQuestionCircle}>
                  <>
                    Would you like to know exactly what the data tells us about the optimal dietary pattern that can
                    benefit billions of people?
                  </>
                  <>
                    Are you worried that you might make the wrong choice on a critical issue like gluten, grains, soy,
                    or protein?
                  </>
                  <>How can you eat out and trust what’s on your plate?</>
                  <>Is there really arsenic in rice?</>
                  <>Does BPA-free plastic really leach estrogenic compounds into your food?</>
                  <>Is frozen as good as fresh? What about canned?</>
                  <>
                    How can you tell what’s right for YOU? What questions should you be asking? What signs should you be
                    looking for? What foods should you be suspicious of?
                  </>
                </ListIcon>
                <h4 className="text-center mt-5">The confusion ends here!</h4>
              </Col>
            </Row>
          </Container>
        </SectionTriangle>
      </div>

      <div id="faqs">
        <SectionTriangle id="" color="white" type="bottom">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center">Frequently Asked Questions</h2>
                <FaqCard />
              </Col>
            </Row>
          </Container>
        </SectionTriangle>

        <SectionTriangle id="" color="light-gray" type="bottom">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center">Is Plant-Powered & Thriving really worth it?</h3>
                <p className="balance-text text-center text-small mb-5">
                  <i>
                    These stories are from people who’ve found success with our programs. Each of them is unique. And so
                    are you! Your experiences will vary, of course. And no, we never offer compensation for these
                    statements!
                  </i>
                </p>
                <TestimonialCarousel
                  list={[
                    variant === 'ad' ? 'cheryl-s-ad' : 'cheryl-s',
                    'gretchen',
                    'linda-b',
                    'cecile-n',
                    variant === 'ad' ? 'donna-n-ad' : 'donna-n'
                  ]}
                />
              </Col>
            </Row>
          </Container>
        </SectionTriangle>
      </div>

      <div id="purchase">
        <SalesCtaSection id="" type="none" />
      </div>
    </>
  );
}
